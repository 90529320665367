
import { defineComponent, PropType } from 'vue';

type ProgressStatus = 'success' | 'failed' | 'warn';
export default defineComponent({
    props: {
        percent: {
            type: Number
        },
        status: {
            type: String as PropType<ProgressStatus>
        },
        width: {
            type: Number,
            default: 280
        }
    }
});
